import React from "react";
import SubHeading from "./SubHeading";

const License = () => {
    return (
        <div className="flex flex-col h-full bg-white p-4 md:rounded">
            <SubHeading heading={"License Information"} />

            <p className="text-sm">
                This dataset is licensed under the Creative Commons Attribution 4.0 International License (CC-BY-4.0).
            </p>
            <br />
            <a href="https://creativecommons.org/licenses/by/4.0/">
                <img src="https://licensebuttons.net/l/by/4.0/88x31.png" alt="Creative Commons License" />
            </a>
        </div>
    );
};

export default License;
